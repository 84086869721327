
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
export default defineComponent({
  name: "EditUserModal",
  mixins: [Globals],
  data() {
    return {
      email: "",
      usestatus: "",
      orgRole: this.getOrgRole(),
      loading: false,
      buttonActive: true,
    };
  },
  components: {},

  methods: {
    deletefunction(formData) {
      this.loading = true;
      if (this.usestatus == "Active") {
        this.Enable = false;
      } else {
        this.Enable = true;
      }
      formData.organizationId = this.globalData.organizationId;
      formData.organizationType = this.checkRole();
      formData.data.email = this.email;
      formData.data.enable = this.Enable;
      ApiService.put("/edit/user", formData)
        .then((data) => {
            this.loading = false;
         
        this.$emit("deleteTitle", "false");
         hideModal(this.deleteCustomerModalRef);
         ElNotification({
            title: 'Success',
            message: 'User Status Changed!',
            type: 'success',
            duration: 2000,
            position: 'top-right'
          })


        })
        .catch((error) => {
            this.loading=false
            ElNotification({
            title: 'Error',
            message: JSON.stringify(error.response.data['errors'][0]['errorMessage']) ,
            type: 'error',
            duration: 2000,
            position: 'top-right'
          })

        });
    },
  
    formReset() {
      (this.email = ""),
        (this.name = ""),
        (this.role = ""),
        (this.mobileno = "");
    },

    callUpdate(useremail, userstatus) {
      this.email="";
      this.usestatus="";
      this.email = useremail;
      this.usestatus = userstatus;
    },
    checkRole() {
      if (this.orgRole == "consumer") this.orgRole = "C";
      else if (this.orgRole == "manufacturer") this.orgRole = "M";
      else if (this.orgRole == "api_provider") this.orgRole = "P";
      else if (this.orgRole == "1SB") this.orgRole = "P";
      return this.orgRole;
    },
  },

  setup() {
    const deleteCustomerModalRef = ref<null | HTMLElement>(null);
    const formData = ref({
      application: "",
      organizationType: "",
      organizationId: "",
      data: {
        email: "",
        enable: true,
      },
    });
    return {
      formData,
      deleteCustomerModalRef
    };
  },
});
